.paymentMethod {
    padding: 0 30px;
    display: flex;
    flex-direction: column;
}

.paymentMethod__input {
    width: 100%;
}

.paymentMethod__inputRow {
    display: flex;
    justify-content: space-between;
}

.paymentMethod__input--half {
    flex: 1;
}

.paymentMethod__input--half:first-child {
    margin-right: 5px;
}

.paymentMethod__input--half:last-child {
    margin-left: 5px;
}

.stripeInput {
    border: 1px solid rgba(20, 18, 114, 0.5);
    border-radius: 3px;
    padding: 0.8em 10px;
    margin-bottom: 18px
}