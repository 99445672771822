.alert {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.alert__logo {
    margin-top: 10px;
    flex-basis: 100%;
    height: 72px;
}

.alert__title {
    margin: 10px 0;
    font-weight: 900;
    font-size: 1.2em;
    flex-basis: 100%;
    text-align: center;
}

.alert__message {
    flex-basis: 100%;
    text-align: center;
    margin-bottom: 5px;
}