.ledger {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.ledger__title {
  font-weight: 900;
}

.ledger__filter {
  height: fit-content;
  display: flex;
  justify-content: center;
  margin-top: 5px;
  margin-right: 5px;
  margin-bottom: 1px;
}

.ledger__filter__input {
  flex-basis: 100%;
  border-radius: 5px;
  border: 1px solid #979797;
}

.ledger__messages {
  z-index: 1;
  flex-basis: 100%;
  overflow: scroll;
}

.ledger__messages__list {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  align-items: stretch;
}

.ledger__scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.ledger__messages::-webkit-scrollbar,
.ledger__scroll::-webkit-scrollbar,
.ledger__add__input::-webkit-scrollbar,
.message__text--edit::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.ledger__messages,
.ledger__add__input,
.message__text--edit {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.message,
.message--me {
  flex-basis: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 2px 0px;
}

.message--delete {
  animation: border-pulsate 2s infinite;
}

.message__top,
.message__top--me {
  flex-basis: 100%;
  display: flex;
  align-items: flex-end;
  margin: 5px 0;
}

.message__top--me {
  justify-content: flex-end;
}

.message__bottom {
  flex-basis: 100%;
}

.message__poster {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.message__tool {
  margin-top: 1px;
}

.message__tool:hover {
  background-color: #f1f2f2;
}

.message__bubble,
.message__bubble--me {
  padding: 10px;
  border: 0.5px solid #f1f2f2;
  display: flex;
  flex-wrap: wrap;
}

.message__bubble {
  background: #f1f2f2;
  border-radius: 15px 15px 15px 0px;
}

.message__bubble--me {
  background: #4e67d8;
  color: #ffffff;
  border-radius: 15px 15px 0px 15px;
}

.message__info {
  flex-basis: 100%;
  display: flex;
  align-items: baseline;
  margin-top: 5px;
}

.message__name,
.message__name--me {
  font-size: 0.8em;
  margin-right: 5px;
  font-weight: 900;
}

.message__name {
  color: #4e67d8;
}

.message__name--me {
  color: #ffffff;
}

.message__tag {
  font-size: 0.6em;
  width: fit-content;
}

.message__text {
  flex-basis: 100%;
  display: inline-block;
  word-break: break-word;
  white-space: pre-line;
}

.message__text--edit {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  box-sizing: border-box;
  resize: none;
  color: #000000;
  background-color: #ffffff;
  border: none;
  border-radius: 15px;
  width: 100%;
  margin-right: 5px;
  padding: 10px;
}

.message__text--edit:focus {
  outline: none;
}

.message__timestamp,
.message__timestamp--me {
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.4px;
  color: #bababa;
}

.message__timestamp {
  text-align: right;
}

.message__timestamp--me {
  text-align: left;
}

.ledger__input {
  display: flex;
  flex-wrap: wrap;
}

.ledger__add {
  flex-basis: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 0 5px 0;
  border-radius: 5px;
}

.ledger__add__return {
  position: relative;
  right: 0;
}

.ledger__add__input {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
  resize: none;
  box-sizing: border-box;
  height: 8em;
  flex-basis: 100%;
  padding: 10px 10px 40px 10px;
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 5px;
}

.ledger__add__input:focus {
  outline: none;
}

.ledger__add__input::placeholder {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: rgba(36, 36, 36, 0.5);
}

.ledger__publish {
  flex-basis: 100%;
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: 100px;
  right: 20px;
}

.ledger__publish__btn {
  padding: 8px 16px;
  box-sizing: border-box;
  border-radius: 50px;
  color: #ffffff;
  font-weight: bold;
  font-size: 0.7em;
}

.ledger__publish__btn--disabled {
  background: #919191;
  border: 1px solid #919191;
}

.ledger__publish__btn--enabled {
  background: #4e67d8;
  border: 1px solid #4e67d8;
}

.ledger__publish__btn--enabled:hover {
  cursor: pointer;
}

.ledger__publish__btn--enabled:active {
  background-color: #ffffff;
  color: #4e67d8;
}

.none {
  display: none;
}

.ledger__empty {
  display: flex;
  flex-direction: column;
  padding-top: 100px;
}

.ledger__empty__title {
  text-align: center;
  font-weight: 900;
  font-size: 15px;
}

.ledger__empty__text {
  padding: 8px 16px 0 16px;
  color: #757575;
  font-size: 15px;
  text-align: center;
}