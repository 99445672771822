.buttonSolid__btn {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  padding: 0.7em 1em;
  border-radius: 10em;
  padding: 0px 10px;
  height: 2em;
}

.buttonSolid__btn--inActive {
  pointer-events: none;
}

.buttonSolid__btn--blue {
  color: white;
  background-color: var(--blue);
  transition: 0.15s;
}

.buttonSolid__btn--blue:hover {
  background-color: #465cc2;
}

.buttonSolid__btn--blue:active {
  background-color: #3c52b3;
}

/* Not using the :disabled element because colors could be different */
.buttonSolid__btn--blue--disabled {
  color: var(--lightGray);
  background-color: var(--gray);
}

.buttonSolid__btn--blue--disabled:hover {
  color: var(--lightGray);
  background-color: var(--gray);
}

.buttonSolid__btn--blue--disabled:active {
  color: var(--lightGray);
  background-color: var(--gray);
}

.buttonSolid__btn--darkBlue {
  color: white;
  background-color: var(--darkBlue);
  transition: 0.15s;
}

.buttonSolid__btn--darkBlue:hover {
  background-color: var(--darkBlue);
  color: var(--gray);
}

.buttonSolid__btn--darkBlue:active {
  background-color: var(--darkBlue);
  color: var(--gray);
}

.buttonSolid__btn--red {
  color: white;
  background-color: var(--darkRed);
  transition: 0.15s;
}

.buttonSolid__btn--red:hover {
  background-color: #f0355b;
}

.buttonSolid__btn--red:active {
  background-color: #e22d51;
}

/* Not using the :disabled element because colors could be different */
.buttonSolid__btn--red--disabled {
  background-color: #fa40655c;
  color: var(--lightGray);
  pointer-events: none;
}

.buttonSolid__btn--red--disabled:hover {
  background-color: #fa40655c;
  color: var(--lightGray);
  pointer-events: none;
}

.buttonSolid__btn--red--disabled:active {
  background-color: #fa40655c;
  color: var(--lightGray);
  pointer-events: none;
}

.buttonSolid__btn--gray {
  color: var(--darkGray);
  background-color: var(--lightGray);
  transition: 0.15s;
}

.buttonSolid__btn--gray:hover {
  background-color: #e6e6e6;
}

.buttonSolid__btn--gray:active {
  background-color: var(--gray);
}
