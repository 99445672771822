.billingContainer {
    box-sizing: border-box;
    margin: 0 30px 16px 30px;
}

.billing {
    margin-top: 20px;
    border: 1px solid #D8D8D8;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.billing__top,
.billing__top--selected {
    height: 54px;
    font-weight: 900;
    display: flex;
    align-items: center;
    padding: 0 15px;
    border-radius: 10px 10px 0 0;
}


.billing__top {
    color: rgba(36, 36, 36, 0.7);
    background: #D8D8D8;
}

.billing__top--selected {
    color: #fff;
    background-color: #4E67D8;
}

.billing__icon,
.billing__icon--selected {
    margin-right: 16px;
    height: 18px;
    width: 18px;
}

.billing__icon {
    color: rgba(36, 36, 36, 0.3);
}

.billing__icon--selected {
    color: #fff;
}

.billing__main {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 40px 60px;
}

.billing__section {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.billing__section__title,
.billing__section__title--selected {
    font-size: 13px;
    font-weight: 500;
    text-align: center;
}

.billing__section__title {
    color: rgba(36, 36, 36, 0.4);
}

.billing__section__title--selected {
    color: rgba(36, 36, 36, 0.6);
}

.billing__section__content,
.billing__section__content--selected {
    font-size: 46px;
    font-weight: 900;
    text-align: center;
}

.billing__section__content {
    color: rgba(36, 36, 36, 0.4);
}

.billing__section__content--selected {
    color: rgba(36, 36, 36, 1);
}

.billing__section__icon,
.billing__section__icon--selected {
    font-size: 20px;
    font-weight: 900;
    text-align: center;
}

.billing__section__icon {
    color: rgba(36, 36, 36, 0.2);
}

.billing__section__icon--selected {
    color: rgba(36, 36, 36, 0.4);
}

.billing__section__subtitle,
.billing__section__subtitle--selected {
    font-size: 10px;
    font-weight: 900;
    text-align: center;
}

.billing__section__subtitle {
    color: rgba(36, 36, 36, 0.2);
}

.billing__section__subtitle--selected {
    color: rgba(36, 36, 36, 0.4);
}

.billing__red {
    color: #FA4065;
}

.billing__strikethrough {
    text-decoration: line-through;
}

.billing__spacer__top {
    height: 13px;
}

.billing__spacer__bottom {
    height: 10px;
}