.reviewPlan {
    padding: 30px;
    height: 100%;
}

.reviewPlan__section:last-child {
    margin-top: 30px;
}

.reviewPlan__section__title {
    color: var(--darkBlue);
    font-weight: 700;
    border-bottom: 3px solid #14127233;
    padding-bottom: 10px;
    margin-bottom: 12px;
}

.reviewPlan__section__subhead {
    font-weight: 700;
    margin-bottom: 10px;
}

.reviewPlan__section__text {
    line-height: 22px;
    color: rgba(36, 36, 36, 0.7);
    font-size: 14px;
}