/* Locations (selected using props) */
.profileImage__container--header {
  margin-right: 5px;
  height: 40px;
  pointer-events: none;
}

.profileImage__container--drawer {
  margin-right: 15px;
  height: 54px;
  pointer-events: none;
}

.profileImage__container--drawerHeader {
  margin-right: 10px;
  height: 54px;
  pointer-events: none;
}

.profileImage__container--list {
  margin-right: 13px;
  height: 54px;
  pointer-events: none;
}

.profileImage__container--ledger {
  font-size: 12px;
  top: 13px;
  position: relative;
  margin: 0 5px;
  height: 20px;
  pointer-events: none;
}

/* Sizes (selected using props) */
.profileImage__size--small {
  width: 20px;
  height: 20px;
}

.profileImage__size--medium {
  width: 40px;
  height: 40px;
}

.profileImage__size--large {
  font-size: 30px;
  width: 54px;
  height: 54px;
}

/* Not-prop based */
.teammateBadge {
  font-size: 10px;
  transform: translateY(-14px);
  width: 20px;
  height: 20px;
}

.teammateBadge--ledger {
  font-size: 6px;
  transform: translateY(-6px) translateX(-2px);
  width: 10px;
  height: 10px;
}

.teammateBadge--drawerHeader {
  font-size: 14px;
  transform: translateY(-12px) translateX(2px);
  width: 25px;
  height: 25px;
}

/* For profile image tag */
.profileImage__img {
  border-radius: 50%;
}
