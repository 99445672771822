.header {
  width: 100%;
  height: 54px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  z-index: 1000;
}

.header__logo {
  height: 70%;
  justify-self: flex-start;
  display: flex;
  align-items: center;
}

.header__logo__img {
  height: 85%;
  margin-right: 5px;
}

.header__logo__title {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 1.4em;
  pointer-events: none;
}

.header__user {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2%;
}

.header__add {
  flex-basis: 4%;
  text-align: center;
  height: 70%;
  margin-right: 2%;
}

.header__menu {
  position: fixed;
  top: 0;
}

.header__logout {
  height: 70%;
  padding: 0 10px;
}

.header__buttons {
  display: flex;
  position: relative;
  margin-left: 20px;
}

@media only screen and (max-width: 768px) {
  .header__logo__title,
  .header__brokerage {
    display: none;
  }
}

.menuItem__title {
  color: #141272;
  font-weight: 700;
  margin-left: 10px;
}

.helpDialog {
  padding: 32px 24px;
}

.helpDialog__title {
  font-weight: 900;
  font-size: 22px;
  margin-bottom: 24px;
}

.helpAdminList {
  display: flex;
  flex-wrap: wrap;
}

.helpAdmin {
  flex-basis: 28%;
  margin: 32px 16px;
}

.helpAdmin__name {
  font-weight: 600;
}

.helpDialog__footer {
  background-color: rgba(196, 196, 196, 0.2);
  padding: 8px;
  border-radius: 4px;
  font-size: 13px;
}
