/* LANES */
.lanes,
.locations {
  box-sizing: content-box;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 5px;
}

.locations__titleContainer,
.lanes__titleContainer {
  flex-basis: 100%;
}

.lanes__title,
.locations__title {
  flex-basis: 100%;
}

.lanes__table__row {
  height: 50px;
  cursor: pointer;
}

.lanes__inputContainer {
  display: flex;
  justify-content: center;
}

.lanes__input {
  text-align: center;
}

.lanes__input::-webkit-outer-spin-button,
.lanes__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.lanes__input[type="number"] {
  -moz-appearance: textfield;
}

/* CUSTOMER BIO */

.customerBio {
  box-sizing: border-box;
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 20px;
}

.customerBio__bio {
  border: 1px solid #ffffff;
}

.customerBio__bio,
.customerBio__bio--edit {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  resize: none;
  width: 100%;
  white-space: pre-wrap;
  box-sizing: border-box;
  font-size: 0.9em;
  padding: 10px;
  border-top: 1px solid rgba(210, 213, 213, 0.5);
}

.customerBio__bio--edit {
  border: 1px solid #dddddd;
  border-radius: 5px;
}

.customerBio__bio--edit:focus {
  outline: none;
  border: 1px solid #dddddd;
  box-shadow: 0px 4px 8px rgba(215, 215, 221, 0.25);
  border-radius: 5px;
}

.customerBio__bio--edit::-webkit-scrollbar,
.customerBio__bio::-webkit-scrollbar {
  display: none;
}

.customerBio__subtext {
  font-size: 0.5em;
  color: #242424;
}

.customerBio__btns {
  margin-right: 5px;
  margin-bottom: 2px;
  display: flex;
  align-items: baseline;
}

.customerBio__btns__cancel {
  font-size: 0.8em;
  margin-right: 5px;
  color: #757575;
}

.customerBio__btns__cancel:hover {
  cursor: pointer;
  font-weight: 600;
}

.customerBio__btns__save {
  font-size: 0.8em;
  font-weight: 600;
  padding: 5px 10px;
  color: #4e67d8;
  border: 1px solid #4e67d8;
  border-radius: 50px;
}

.customerBio__btns__save:hover {
  cursor: pointer;
  color: #ffffff;
  background-color: #4e67d8;
}

.customerBio__btns__save:active {
  color: #4e67d8;
  background-color: #ffffff;
}

.marketFeedback {
  margin-top: 3rem;
  overflow: visible;
}

.marketFeedback__add {
  color: #757575;
  font-size: 0.9em;
}

.marketFeedback__add:hover {
  cursor: pointer;
}

.marketFeedback__datetime {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  text-align: left;
}

.marketFeedback__time {
  font-size: 0.8em;
  color: rgba(36, 36, 36, 0.8);
}

.marketFeedback__rate {
  border: none;
  outline: none;
}

.marketFeedback__btnContainer {
  justify-content: center;
  align-items: center;
}

.marketFeedback__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 2px 8px 4px 8px;
  font-size: 1.25em;
  font-weight: 900;
  border-radius: 3px;
}

.marketFeedback__btn:hover {
  cursor: pointer;
}

.marketFeedback__btn:active {
  color: #4e67d8;
  background: #fff;
}

.marketFeedback__tableBodyContainer {
  display: flex;
  flex-flow: column nowrap;
  justify-items: center;
  max-height: 12em;
}

.locationTable__tableBodyContainer {
  display: flex;
  flex-flow: column nowrap;
  justify-items: center;
  max-height: 13.5em !important;
}

.editDialog {
  box-sizing: content-box;
  padding: 35px 30px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.editDialog__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.editDialog__bottom {
  flex: 1;
  display: flex;
  max-width: 100%;
}

.editDialog__btns {
  display: flex;
}

.editDialog__title {
  margin-right: 100px;
  font-size: 22px;
  font-weight: 900;
}

.editDialog__input {
  flex: 4;
  margin-top: 30px;
}

.editDialog__input--quarter {
  flex: 1 4;
  margin-right: 10px;
}

.editDialog__input--half {
  flex: 2;
  margin-right: 10px;
}

.editDialog__middle {
  display: flex;
  width: 100%;
  align-items: center;
}

.editDialog__input--full {
  flex-basis: 100%;
}

.editDialog__image {
  margin-right: 8px;
  margin-bottom: 16px;
}

.favoriteCarriers__list {
  display: flex;
  flex-flow: column nowrap;
  max-height: calc((3.85em + 36px) * 5);
  overflow-y: scroll;
}

.favoriteCarriers__sort {
  display: flex;
  align-items: center;
  padding: 8px 0;
}

.favoriteCarriers__ascending {
  transform: scaleY(-1);
}

.favoriteCarriers__sortText {
  font-weight: 500;
}

.favoriteCarriers__sortItem {
  padding: 16px 40px 16px 16px;
  font-weight: 500;
}

.favoriteCarriers__sortItem:hover {
  color: #4e67d8;
  background-color: #edeffb;
}
