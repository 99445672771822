fieldset {
  display: flex;
  flex-flow: column nowrap;
  border: none;
}

legend {
  margin-bottom: 0.5em;
  color: #666666;
  font-weight: 500;
  font-size: 0.9em;
}
