.idCard__container {
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  grid-template-rows: 24px;
  margin: 18px 0;
  min-height: 3.85rem;
  /* flex-grow used by Tagged Teammates to take up full flex container */
  flex-grow: 1;
}

.idCard__profileImage {
  grid-column: 1;
  grid-row: 1 / 4;
}

.idCard__tags {
  grid-column: 2;
  grid-row: 1;
  align-self: center;
  display: flex;
  flex-flow: row nowrap;
  font-size: 0.85em;
}

.idCard__name {
  grid-column: 2;
  grid-row: 2;
  font-weight: 600;
  font-size: 1rem;
}

.idCard__name--noTags {
  grid-row: 1;
  align-self: end;
}

.idCard__name--btn {
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  text-align: left;
  cursor: pointer;
  width: 90%;
}

.idCard__title {
  grid-column: 2;
  grid-row: 3;
  font-size: 0.8em;
  color: #242424;
  opacity: 0.8;
}

.idCard__title--noTags {
  grid-row: 2;
  align-self: end;
}

.idCard__btns {
  display: flex;
  flex-flow: column nowrap;
  grid-column: 3;
  grid-row: 1 / 4;
  place-content: space-between;
}

.idCard__btns--big {
  display: flex;
  flex-flow: row wrap;
  grid-column: 1 / 3;
  grid-row: 4;
  place-content: space-between;
  margin-top: 15px;
}

.idCard__phone {
  flex-basis: 48%;
}

.idCard__email {
  flex-basis: 48%;
}

/* idCard name btn */
.idCard__name--btn > span {
  transition: all 0.2s;
}

.idCard__name--btn:hover > span {
  border-radius: 2px;
  padding-right: 8px;
  padding-left: 3px;
}

.idCard__name--btn:active > span {
  background-color: rgba(0, 0, 0, 0.102);
}

.idCard__name--btn--red:hover > span {
  background-color: #feebef;
}

.idCard__name--btn--red:active > span {
  background-color: #fdd3dc;
}

.idCard__name--btn--blue:hover > span {
  background-color: #ebedf7;
}

.idCard__name--btn--blue:active > span {
  background-color: #ccd4fa;
}
