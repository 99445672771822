.drawer > div {
  box-sizing: border-box;
}

.drawerContainer {
  pointer-events: none;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  bottom: 0;
}

.drawer {
  pointer-events: all;
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  max-height: 90vh; /* just used for transition. true height is addition of drawer__upper + drawer__lower = 85vh */
  transition: max-height 0.25s ease-in;
  border-radius: 15px 15px 0 0;
  box-shadow: #00000032 0px -3px 14px 6px;
  overflow: hidden;
}

.drawer--close {
  max-height: 9rem;
}

.drawer__open {
  padding-bottom: 40px;
}

.drawer__content {
  min-height: 132px;
  overflow-y: scroll;
  padding-bottom: 5rem;
  height: 100%;
}

.drawer__content--closed {
  overflow-y: hidden;
}

.drawer__upper {
  height: 6rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.drawer__expand {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.drawer__expand__button {
  background-color: transparent;
  border: none;
  outline: none;
  transition: background-color 0.15s;
}

.drawer__expand__button:hover {
  background-color: var(--lightGray);
}

.drawer__arrow--open {
  transform: scaleY(-1);
}

.drawer__top {
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  margin: 0 5%;
}

.drawer__column--2 {
  flex-basis: 50%;
  display: flex;
}

.drawer__column--right {
  justify-content: flex-end;
}

.drawer__noImage {
  flex-wrap: wrap;
}

.drawer__image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.drawer__info {
  display: flex;
  flex-wrap: wrap;
}

.drawer__row {
  flex-basis: 100%;
  display: flex;
  align-items: center;
  margin: 2px 0;
}

.drawer__user__img {
  flex-basis: 20%;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 5px;
  border: 2px solid #fff;
}

.drawer__user__stamp {
  font-size: 1.8em;
  flex-basis: 20%;
  padding: 12px 20px;
  border-radius: 50%;
  margin-right: 5px;
}

.drawer__user__img--current {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid #4e67d8;
  margin-right: 5px;
}

.drawer__name {
  color: #000000;
  font-weight: 900;
  font-size: 1.5em;
  margin-right: 5px;
}

.drawer__title {
  margin-left: 2px;
  font-size: 0.9em;
  margin-right: 5px;
}

.drawer__customerName {
  font-size: 0.9em;
}

.drawer__collapse {
  width: 100%;
}

.drawer__lower {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding: 0px 4% 5px 4%;
}

.drawer__lower__top {
  height: fit-content;
  padding: 15px 0 20px 0;
}

.drawer__lower__columns {
  display: flex;
}

.drawer__lower__column {
  flex-basis: 33%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 15px;
}

.drawer__lower__column::-webkit-scrollbar {
  display: none;
}

.drawer__lower__title {
  flex-basis: 100%;
  font-weight: 900;
}

.drawer__ledger {
  width: 100%;
  height: 400px;
  border: 1px solid #979797;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.drawer__ledger__filter {
  flex-basis: 100%;
  height: 10%;
  margin: 5px;
  display: flex;
  justify-content: center;
}

.drawer__ledger__filter__input {
  flex-basis: 100%;
  border-radius: 5px;
  border: 1px solid #979797;
}

.drawer__stamp {
  height: 2.1em;
  width: 2.1em;
}

.drawer__tooltip {
  display: flex;
  align-items: center;
}

.drawer__tooltip__symbol {
  font-size: 1.5em;
  margin-right: 2px;
}

@media all and (min-height: 8rem) {
  .drawer {
    height: 50vh;
  }
}

@media all and (min-height: 27rem) {
  .drawer {
    height: 76vh;
  }
}

@media all and (min-height: 35rem) {
  .drawer {
    height: 78vh;
  }
}

@media all and (min-height: 50rem) {
  .drawer {
    height: 83vh;
  }
}
