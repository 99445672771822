.buttonTextOnly__btn {
  color: var(--darkGray);
  font-weight: 400;
  display: grid;
}

.buttonTextOnly__standard {
  grid-column: 1;
  grid-row: 1;
  opacity: 1;
  transition: 0.15s;
}

.buttonTextOnly__bold {
  grid-column: 1;
  grid-row: 1;
  font-weight: 800;
  opacity: 0;
  transition: 0.15s;
}

.buttonTextOnly__btn:hover > .buttonTextOnly__bold {
  opacity: 1;
}

.buttonTextOnly__btn:hover > .buttonTextOnly__standard {
  opacity: 0;
}
