.quickTags {
  display: flex;
  align-items: center;
  padding: 15px 0;
  margin: 0 15px;
  border-top: 3px solid #f1f2f2;
  border-bottom: 3px solid #f1f2f2;
  font-size: 0.8em;
  font-weight: 500;
  width: 100%;
}

.quickTag__add {
  height: 2em;
  font-weight: 700;
  color: #4e67d8;
  padding: 0px 10px;
  border: 2px solid rgba(78, 103, 216, 0.4);
  border-radius: 10em;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.buttonOutlined__btn {
  border: solid var(--darkRed) 2px;
  border-radius: 10em;
  padding: 0px 10px;
  height: 2em;
  color: var(--darkRed);
  font-weight: 700;
  white-space: nowrap;
}

.quickTag__add:active {
  background-color: #4e67d8;
  color: #fff;
}

.quickTags__list {
  flex-basis: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.quickTags__list::-webkit-scrollbar {
  display: none;
}

.quickTag {
  font-size: 1em !important;
  padding: 10px 15px !important;
  margin: 10px 5px;
  background-color: rgba(20, 18, 114, 0.08);
  color: #141272;
  border-radius: 100px !important;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.quickTag__emoji {
  margin-right: 2px;
  font-size: 1.1em;
  grid-row: 1;
  grid-column: 1;
}

.quickTag__delete {
  padding: 1px 6px 2px 6px;
  margin-left: 5px;
  border-radius: 100%;
}

.quickTag__delete:hover {
  background-color: rgba(20, 18, 114, 0.08);
}

.quickTag__content {
  display: flex;
  padding: 1px;
}

.addTags {
  padding: 30px 0 0 0 !important;
}

.addTags__title {
  margin: 0 0 0 30px;
  border: none;
}

.addTags__list {
  flex-basis: 100%;
  padding: 5px 0 0 0;
  display: flex;
  flex-wrap: wrap;
}

.addTags__section {
  flex-basis: 100%;
}

.addTags__section__header {
  color: #757575;
  font-weight: 900;
}

.addTags__tagList {
  flex-basis: 100%;
  display: flex;
  flex-wrap: wrap;
}

.addTags__subsection__container {
  flex-basis: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.addTags__subsection--full {
  flex-basis: 40%;
  margin-top: 25px;
}

.addTags__subsection--full {
  flex-basis: 100%;
  margin-top: 25px;
}

.addTags__subsection__title {
  margin-left: 5px;
  margin-bottom: 6px;
  color: #757575;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  letter-spacing: -0.5px;
  font-weight: 700;
  font-size: 0.8em;
}

.addTags__inputContainer {
  display: flex;
  align-items: center;
}

.addTags__input {
  display: flex;
  align-items: center;
  border: 1px solid #d3d3d3;
  border-radius: 100px;
}

.addTags__input__field {
  width: 100px;
  text-align: center;
  padding: 10px;
  border-radius: 100px;
  border: none;
  outline: none;
  font-weight: 900;
}

.addTags__add {
  margin-left: 5px;
  color: #fff;
  border-radius: 100%;
  padding: 6px 10px 8px 10px;
  font-weight: 800;
  border: 1px solid #fff;
}

.addTags__add--disabled {
  background-color: #919191;
}

.addTags__add--enabled {
  background-color: #4e67d8;
}

.addTags__add--enabled:active {
  background-color: #fff;
  color: #4e67d8;
  border: 1px solid #4e67d8;
}

.addTags__input__spacer {
  min-width: 25px;
  visibility: hidden;
}

.addTags__input__btn,
.addTags__input__btn--selected {
  min-width: 30px;
  text-align: center;
  margin-right: 5px;
  padding: 5px 10px;
  border-radius: 100px;
  background: #eaeaea;
  color: #757575;
  font-size: 0.8em;
  font-weight: 900;
}

.addTags__input__btn--selected {
  background-color: #4e67d8;
  color: #fff;
}

.addTags__input__field::-webkit-outer-spin-button,
.addTags__input__field::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.addTags__custom {
  margin: 10px 0;
  flex-basis: 100%;
  display: flex;
  border: 1px solid #dddddd;
  border-radius: 3px;
}

.addTags__custom__input {
  border-radius: 3px;
  flex-basis: 100%;
  display: flex;
}

.addTags__custom__field {
  padding: 10px 15px;
  flex-basis: 93%;
  border: none;
  outline: none;
}

.addTags__custom__btn--enabled,
.addTags__custom__btn--disabled {
  flex-basis: 7%;
  height: 100%;
  color: #fff;
  border-radius: 0 2px 2px 0;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
}

.addTags__custom__btn--disabled {
  background-color: #919191;
  cursor: auto;
}

.addTags__custom__btn--enabled {
  background-color: #4e67d8;
}

.addTags__section--selected {
  background-color: #fff !important;
  border-bottom: none !important;
}
