.buttonOutlined__btn {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  border: solid var(--darkRed) 1.75px !important;
  border-radius: 10em;
  padding: 0px 10px;
  height: 2em;
  color: var(--darkRed);
  font-weight: 700;
  white-space: nowrap;
  transition: 0.15s;
}

.buttonOutlined__btn:hover {
  color: white;
  background-color: var(--darkRed);
}

.buttonOutlined__btn:active {
  color: white;
  background-color: #e93a5d;
}

.buttonOutlined__btn--undefined--disabled,
.buttonOutlined__btn--undefined--disabled:hover,
.buttonOutlined__btn--undefined--disabled:active {
  background-color: transparent;
  color: var(--darkRed);
}

.buttonOutlined__btn--green {
  border: solid var(--darkGreen) 1.75px !important;
  color: var(--darkGreen) !important;
}

.buttonOutlined__btn--green:hover {
  color: white !important;
  background-color: var(--darkGreen);
}

.buttonOutlined__btn--green:active {
  color: white;
  background-color: #156624;
}

.buttonOutlined__btn--green--disabled,
.buttonOutlined__btn--green--disabled:hover,
.buttonOutlined__btn--green--disabled:active {
  background-color: transparent !important;
  color: var(--darkGreen) !important;
}

.buttonOutlined__btn--blue {
  border: solid var(--blue) 1.75px !important;
  color: var(--blue) !important;
}

.buttonOutlined__btn--blue:hover {
  color: white !important;
  background-color: var(--blue);
}

.buttonOutlined__btn--blue:active {
  color: white;
  background-color: #3c52b3;
  border-color: #3c52b3;
}

.buttonOutlined__btn--blue--disabled,
.buttonOutlined__btn--blue--disabled:hover,
.buttonOutlined__btn--blue--disabled:active {
  background-color: transparent !important;
  color: var(--darkBlue) !important;
}

.buttonOutlined__btn--darkBlue {
  border: solid var(--darkBlue) 1.75px !important;
  color: var(--darkBlue) !important;
}

.buttonOutlined__btn--darkBlue:hover {
  color: white !important;
  background-color: var(--darkBlue);
}

.buttonOutlined__btn--darkBlue:hover > svg > path {
  fill: white;
}

.buttonOutlined__btn--blue:active {
  color: white;
  background-color: var(--darkBlue);
  border-color: var(--darkBlue);
}
