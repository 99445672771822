.formInviteWorkflowInputs {
    box-sizing: border-box;
    padding-top: 30px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.formInviteWorkflowInputs__upgrade {
    margin: 0px 30px;
    background-color: rgba(250, 64, 101, 0.05);
    color: var(--darkRed);
    padding: 15px 20px;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
}

.formInviteWorkflowInputs__list {
    flex-grow: 1;
    margin: 8px 30px 0 30px;
    height: calc(18em - 8px);
    overflow-y: scroll;
}

.formInviteWorkflowInputs__add {
    text-align: left;
    margin: 8px 30px;
    color: #4E67D8;
}

.formInviteWorkflowInputs__inputContainer {
    display: flex;
    align-items: center;
}

.formInviteWorkflowInputs__inputDelete {
    position: relative;
    margin-left: -20px;
    margin-top: 8px;
    color: var(--darkRed);
    font-weight: 700;
    font-size: 18px;
}