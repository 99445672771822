.buttonGear__button > svg > path {
  transition: all 0.15s;
}

.buttonGear__button:hover > svg > path {
  fill: var(--darkBlue);
}

.buttonGear__button--active--true > svg > path {
  fill: var(--darkBlue) !important;
}
