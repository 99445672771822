button {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

button:disabled {
  cursor: auto !important;
}

.pillBtn__red {
  background-color: var(--darkRed);
  border-radius: 10em;
  padding: 0px 30px;
  height: 3em;
  color: white;
  font-weight: 700;
}

.btn--wide {
  width: 100%;
}

.pillBtn__red--disabled {
  background-color: #fa40655c;
  color: var(--lightGray);
  pointer-events: none;
}

.pillBtn__red--form {
  margin-right: 25px;
}

.pillOutlineBtn__red {
  border: solid var(--darkRed) 2px;
  border-radius: 10em;
  padding: 0px 30px;
  height: 3em;
  color: var(--darkRed);
  font-weight: 700;
}

.pillBtn__grey {
  background-color: #E0E0E0;
  border-radius: 10em;
  padding: 0px 30px;
  height: 3em;
  color: #757575;
  font-weight: 700;
}

.pillBtn__grey--form {
  margin-left: 25px;
}

.editBtn {
  display: flex;
  align-items: center;
  border-radius: 2px;
  padding: 1px 2px 1px 0;
  border: 2px solid white;
}

.editBtn:hover {
  border-bottom: 2px solid #eaeaea;
  cursor: pointer;
}

.editBtn__img {
  transform: scaleX(-1);
  color: #757575;
}

.editBtn__txt {
  font-size: 0.7em;
  color: #757575;
  font-weight: 500;
}

.editBtn__green {
  color: #197429 !important;
}

.editBtn__yellow {
  color: #ffb913 !important;
}

.deleteBtn {
  display: flex;
  margin-left: 5px;
  align-items: center;
  padding: 1px 6px 2px 6px;
  cursor: pointer;
  font-size: 25px;
  color: #fa4065;
  font-weight: 1200;
}

.submitBtn {
  display: flex;
  margin-left: 5px;
  align-items: center;
  padding: 1px 6px 2px 6px;
  cursor: pointer;
  font-size: 25px;
  color: #197429;
  font-weight: 1200;
}
