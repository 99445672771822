.headerButtonHome__button {
  display: flex;
  background-color: var(--lightGray);
  border-radius: 100px;
  height: 40px;
  width: 40px;
  padding: 10px;
  cursor: pointer;
  flex-flow: row nowrap;
  align-items: center;
  margin-left: 1em;
  justify-content: center;
  transition: 0.15s;
}
.headerButtonHome__button:hover {
  background-color: var(--gray);
}

.headerButtonHome__button > svg > path {
  fill: var(--darkGray);
}

.headerButtonAdd__button {
  display: flex;
  background-color: var(--darkBlue);
  border-radius: 100px;
  height: 40px;
  padding: 10px;
  padding-right: 19px;
  cursor: pointer;
  flex-flow: row nowrap;
  width: fit-content;
  align-items: center;
  color: white;
}

.headerButtonAdd__button > svg > path {
  fill: white;
  transition: 0.2s;
}

.headerButtonAdd__button:hover > svg > path {
  fill: var(--gray);
}

.headerButtonAdd__button:hover > div {
  color: var(--gray);
}

.headerButtonAdd__button__text {
  font-weight: 700;
}

.headerButtonInvite__button {
  margin-left: 5px;
  background-color: var(--darkRed);
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  width: fit-content;
  color: #fff;
  align-items: center;
  padding: 2px 10px;
  transition: 0.15s;
}

.headerButtonInvite__button > svg > path {
  fill: white;
  transition: 0.15s;
}

.headerButtonInvite__button:hover > svg > path {
  fill: var(--gray);
}

.headerButtonInvite__button:hover {
  color: var(--gray);
}

.headerButtonInvite__button__text {
  font-weight: 700;
}
