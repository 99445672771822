.tooltip__smallCircle {
  border: none;
  background-color: #f1f2f2;
  border-radius: 50%;
  padding: 4px;
  height: 2em;
  width: 2em;
  transition: background-color 0.15s;
}

.tooltip__smallCircle:hover {
  background-color: #dfe2e2;
  transition: background-color 0.15s;
}

.tooltip__smallCircle:active {
  background-color: #dadada;
  transition: background-color 0.15s;
}

.tooltip__smallCircle--visible--true {
  cursor: default;
  background-color: #dadada !important;
}

.tooltip__blueOutline {
  display: flex;
  align-items: center;
  flex-basis: 48%;
  justify-content: center;
  background-color: transparent;
  color: #4e67d8;
  border: 1px solid #4e67d8;
  border-radius: 50px;
  width: 100%;
  padding: 10px 15px;
  transition: background-color 0.15s;
}

.tooltip__blueOutline > svg {
  margin-right: 10px;
  fill: #4e67d8;
}

.tooltip__blueOutline:hover > svg {
  fill: #f9f9f9;
}

.tooltip__blueOutline:hover {
  color: #f9f9f9;
  background-color: #4e67d8;
}

.tooltip__blueOutline:active {
  color: #f9f9f9;
  background-color: #465cc2;
}

.tooltip__blueOutline--visible--true {
  cursor: default;
  color: #f9f9f9 !important;
  background-color: #465cc2 !important;
}

.tooltip__blueOutline--visible--true > svg {
  fill: #f9f9f9 !important;
}

.tooltip__emoji {
  align-self: center;
  font-size: 28px;
  margin-right: 8px;
}
