.main__card {
  padding-bottom: 60px;
}

.settings__card__title {
  flex-basis: 100%;
  margin: 40px 0;
  font-size: 22px;
  font-weight: 900;
}

.settings__card__title--noBottomMargin {
  flex-basis: 100%;
  font-size: 22px;
  font-weight: 900;
}

.settings__card__subtitle {
  flex-basis: 100%;
  color: rgba(36, 36, 36, 0.3);
  margin-top: 15px;
  font-size: 0.8em;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.settings__profile {
  flex-basis: 100%;
  display: flex;
}

.settings__profile__left {
  flex-basis: 55%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-right: 20px;
}

.settings__profile__left1 {
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button_div {
  height: 1.25em;
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
}

.settings__update {
    font-weight: 600;
    border-radius: 50px;
    padding: 15px 30px;
    width: fit-content;
}
.enabled {
  color: #fff;
  background: #fa4065;
}

.disabled {
  color: rgb(62, 64, 67);
  background: #898989;
}



.settings__profile__right {
  flex-basis: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.settings__profile__right1 {
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.settings__profile__input,
.settings__profile__input__double {
  margin-bottom: 40px;
}

input[type=checkbox] {
  transform: scale(1.5);
}

.settings__profile__input__title {
  color: #141272;
  font-weight: 600;
  margin-bottom: 15px;
}

.admin__label {
  margin-bottom:0; 
  margin-right: 10px;
}

.admin__wrapper {
  margin-right: 20px;
  display: flex;
  flex-direction: row;
}

.settings__profile__input__field {
  height: 3em;
  width: 94.5%;
  padding-left: 10px;
  margin-right: 15px;
  font-size: 1em;
  border: 1px solid rgba(20, 18, 114, 0.4);
  border-radius: 3px;
  outline: none;
}

.settings__profile__input__field--half {
  height: 3em;
  width: calc(50% - 30px);
  padding-left: 10px;
  margin-right: 15px;
  font-size: 1em;
  border: 1px solid rgba(20, 18, 114, 0.4);
  border-radius: 3px;
  outline: none;
}

.settings__brokerage__input__field {
  height: 3em;
  width: 94.5%;
  padding-left: 10px;
  margin-right: 15px;
  margin-bottom: 14px;
  font-size: 1em;
  border: 1px solid rgba(20, 18, 114, 0.4);
  border-radius: 3px;
  outline: none;
}

.settings__brokerage__input__field--half {
  height: 3em;
  width: calc(50% - 30px);
  padding-left: 10px;
  margin-right: 15px;
  margin-bottom: 14px;
  font-size: 1em;
  border: 1px solid rgba(20, 18, 114, 0.4);
  border-radius: 3px;
  outline: none;
}

.settings__brokerage__input__field--quarter {
  height: 3em;
  width: calc(25% - 30px);
  padding-left: 10px;
  margin-right: 15px;
  margin-bottom: 14px;
  font-size: 1em;
  border: 1px solid rgba(20, 18, 114, 0.4);
  border-radius: 3px;
  outline: none;
}

.settings__brokerage__select__field--quarter {
  height: 3.2em;
  width: calc(25% - 15px);
  padding-left: 10px;
  margin-right: 15px;
  margin-bottom: 14px;
  font-size: 1em;
  border: 1px solid rgba(20, 18, 114, 0.4);
  border-radius: 3px;
  outline: none;
}

.settings__brokerage__select__field--quarter1 {
  height: 3.2em;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 14px;
  font-size: 1em;
  border: 1px solid rgba(20, 18, 114, 0.4);
  border-radius: 3px;
  outline: none;
}

.settings__profile__select {
  height: 3em;
  width: 97%;
  padding: 10px;
  font-size: 1em;
  border: 1px solid rgba(20, 18, 114, 0.4);
  border-radius: 3px;
  outline: none;
  padding-right: 10px;
}

.settings__upload__select {
  height: 3em;
  width: 100%;
  padding: 10px;
  font-size: 1em;
  border: 1px solid rgba(20, 18, 114, 0.4);
  border-radius: 3px;
  outline: none;
  padding-right: 10px;
}

.settings__profile__photo {
  display: flex;
}

.settings__profile__img {
  display: flex;
  height: 180px;
  width: 180px;
  margin-top: 15px;
  border-radius: 50%;
}

.settings__profile__img:active {
  background-color: #cacaca;
}

.settings__profile__img__delete {
  position: relative;
  background-color: #ffdbe2;
  height: fit-content;
  width: fit-content;
  border-radius: 50%;
  padding: 0 8px 4px 8px;
  bottom: 25px;
  left: 125px;
}

.settings__profile__dialog {
  align-items: baseline;
}

.settings__save,
.settings__save {
  color: #fff;
  background: #fa4065;
  font-weight: 600;
  border-radius: 50px;
  padding: 15px 30px;
  width: fit-content;
}

.manage_comp {
  margin-right: 10px;
}

.settings__password {
  flex-wrap: wrap;
  padding: 0 60px;
}

.settings__password__input {
  flex-basis: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 24px 0;
}

.settings__password__input__title {
  flex-basis: 25%;
  color: #141272;
  font-weight: 600;
  margin-right: 10px;
}

.settings__password__input__field {
  height: 3em;
  flex-basis: 75%;
  font-size: 1em;
  border: 1px solid rgba(20, 18, 114, 0.4);
  border-radius: 3px;
  padding-left: 15px;
}

.settings__teams {
  flex-basis: 80%;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-left: 25px;
}

.settings__teams__header {
  display: flex;
  height: fit-content;
  padding-bottom: 30px;
  border-bottom: 3px solid #e4e4e4;
}

.setting__header__left {
  flex-basis: 80%;
  display: flex;
}

.setting__header__right {
  flex-basis: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.settings__upload__main {
  flex-basis: 100%;
  margin: 45px 60px 0 60px;
}

.settings__teams__header__symbol {
  background-color: #238f8f;
  color: #fff;
  font-weight: 700;
  border-radius: 50px;
  padding: 20px 14px;
  width: 60px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.settings__teams__header__symbol__image {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}

.settings__teams__header__titleContainer {
  margin-left: 15px;
}

.settings__teams__header__title {
  font-weight: 900;
  font-size: 34px;
}

.settings__teams__header__btn {
  display: flex;
  align-items: center;
  height: fit-content;
  width: fit-content;
  white-space: nowrap;
  padding: 15px 15%;
  margin-right: 10px;
}

.settings__teams__list {
  padding-bottom: 60px;
}

.teams__card {
  margin: 30px 0;
}

.team__summary,
.newTeam__title {
  display: flex;
  align-items: center;
  padding-left: 7%;
}

.team__icon {
  display: flex;
  align-items: center;
  font-size: 30px;
  justify-content: center;
  height: 54px;
  width: 54px;
  background-color: #f1f2f2;
  border-radius: 27px;
}

.team__nameContainer {
  margin-left: 20px;
}

.team__name {
  font-size: 22px;
  font-weight: 900;
  margin-bottom: 2px;
}

.team__count {
  font-size: 14px;
}

.team__edit__section {
  padding: 0 7%;
  width: 100%;
}

.team__edit__teamInfo {
  display: flex;
  width: 100%;
  margin-bottom: 15px;
}

.team__edit__icon {
  color: rgba(36, 36, 36, 0.7);
  height: 46px;
  width: 46px;
  margin-right: 15px;
  font-size: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
}

.team__edit__icon:active {
  background-color: #d8d8d8;
}

.team__edit__name {
  width: 100%;
  padding-left: 5px;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
}

.team__edit__title {
  color: #242424;
  opacity: 0.7;
  font-weight: 600;
  margin-bottom: 15px;
}

.team__edit__search {
  display: flex;
  align-items: center;
  border: 1px solid #d8d8d8;
  padding: 0 14px;
}

.team__edit__search__input {
  border: none;
  outline: none;
  height: 3em;
  width: 100%;
}

.team__edit__results {
  border: 1px solid #d2d5d5;
  box-shadow: 7px 7px 10px rgba(215, 215, 221, 0.3);
  position: relative;
  top: 0;
}

.team__edit__teammatesList {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.team__edit__teammate,
.team__add__teammate {
  border-top: 1px solid rgba(210, 213, 213, 0.5);
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.team__add__teammate {
  margin-left: 10px;
}

.team__edit__teammate__left {
  display: flex;
  align-items: center;
}

.team__edit__teammate:first-child {
  border-top: none;
}

.team__edit__teammate__img {
  height: 54px;
  width: 54px;
  border-radius: 50%;
  margin-right: 13px;
}

.team__edit__teammate__name {
  font-size: 17;
  font-weight: 600;
  color: #242424;
  margin-bottom: 2px;
}

.team__edit__teammate__title {
  font-size: 13px;
  color: rgba(36, 36, 36, 0.8);
}

.team__edit__teammate__remove {
  font: Arial;
  font-weight: 700;
  color: #c6c6c6;
  margin-right: 2px;
}

.team__edit__spacer {
  height: 20px;
}

.team__btns {
  display: flex;
  align-items: center;
  margin: 55px 0;
}

.team__add__btn {
  background-color: #fa4065;
  margin-right: 15px;
}

.newTeam__title {
  margin: 50px 0 40px 0;
}

.settings__team__btnContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.settings__team__delete {
  color: #fa4065;
  font-weight: 900;
}

.settings__team__delete:active {
  text-decoration: underline;
}

.settings__user__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.settings__user__btn {
  flex-basis: 20%;
  text-align: center;
  margin-right: 60px;
  margin-top: 30px;
  min-width: 20em;
  display: flex;
  justify-content: center;
  padding: 15px 2%;
}

.settings__user__subtitle {
  color: rgba(36, 36, 36, 0.5);
  font-size: 0.9em;
  margin-top: 10px;
}

.settings__user__search {
  display: flex;
  align-items: center;
  border: 1px solid #d8d8d8;
  padding: 0 14px;
  margin: 30px;
}

.settings__user__input {
  border: none;
  outline: none;
  height: 3em;
  width: 100%;
}

.settings__user__input::placeholder {
  color: rgba(36, 36, 36, 0.5);
}

.settings__user__table {
  margin: 0 30px;
  display: flex;
  flex-direction: column;
  width: 36em;
  align-items: stretch;
  min-height: 20em;
}

.settings__user__trow:nth-child(even) {
  background: rgba(234, 234, 234, 0.5);
}

.settings__user__thead {
  display: flex;
  color: #242424;
  margin-bottom: 15px;
}

.settings__user__trow,
.settings__user__trow--deactivated {
  display: flex;
  align-items: center;
  padding: 15px;
}

.settings__user__trow--deactivated {
  opacity: 0.5;
}

.settings__user__th,
.settings__user__td {
  flex: 2;
  margin-right: 10px;
}

.settings__user__td:nth-last-child(1),
.settings__user__th:nth-last-child(1) {
  flex: 1;
}

.settings__user__th {
  display: flex;
  align-items: center;
}

.settings__user__info {
  display: flex;
  align-items: center;
}

.settings__user__img {
  height: 36px;
  width: 36px;
  border-radius: 50px;
  margin-right: 10px;
}

.settings__user__name {
  color: #242424;
  font-weight: 900;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
}

.settings__user__email {
  font-size: 0.8em;
  color: rgba(36, 36, 36, 0.5);
}

.settings__user__menu {
  margin-top: -8px;
  font-size: 1.3em;
}

.settings__user__status {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.settings__user__popover {
  padding: 10px 20px;
}

.settings__user__popover__btn {
  margin: 10px 0;
  font-weight: 700;
}

.settings__user__tag {
  margin-left: 6px;
}

.popover__btn--purple {
  color: #141272;
}

.popover__btn--red {
  color: #fa4065;
}

.fileUploadContainer {
  flex-basis: 100%;
  background: rgba(20, 18, 114, 0.02);
  display: flex;
  justify-content: center;
  align-content: center;
}

.fileUpload {
  flex-basis: 100%;
  padding: 50px 0;
  width: 25vw;
  height: 20vh;
  border: 1px dashed #141272;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}

.fileUpload__img {
  display: flex;
  justify-content: center;
}

.fileUpload__txt {
  flex-basis: 100%;
  color: #141272;
  text-align: center;
  height: fit-content;
  margin-bottom: 25px;
}

.fileUpload__btn {
  background: #fa4065;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 16px 40px;
  border-radius: 50px;
  height: fit-content;
}

.manageBilling {
  flex-basis: 80%;
  display: flex;
  flex-direction: column;
}

.manageBilling__card {
  margin: 40px 0 0 25px;
  background-color: #fff;
  height: fit-content;
  border-radius: 10px;
  box-shadow: 10px 10px 15px rgba(31, 30, 68, 0.05);
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 60px;
}

.manageBilling__left,
.manageBilling__right {
  flex-basis: 50%;
}

.manageBilling__right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.manageBilling__options {
  flex-basis: 100%;
  padding: 60px 60px 0 60px;
  display: flex;
  flex-wrap: wrap;
}

.manageBilling__options__left,
.manageBilling__options__right {
  flex-basis: 50%;
}

.manageBilling__save {
  margin-top: 32px;
}

.currentPlan,
.paymentInfo,
.paymentHistory {
  width: 100%;
  padding: 0 60px;
  display: flex;
}

.currentPlan__details {
  font-weight: 500;
  margin-bottom: 8px;
}

.currentPlan__count {
  margin-bottom: 8px;
}

.currentPlan__manage {
  color: #4e67d8;
  text-decoration: underline;
  font-weight: 600;
}

.paymentInfo {
  display: flex;
  align-items: center;
}

.paymentInfo__card {
  font-size: 20px;
  font-weight: 900;
  margin-right: 16px;
}

.paymentHistory {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.paymentHistory__trow,
.paymentHistory__thead {
  display: flex;
}

.paymentHistory__td,
.paymentHistory__th {
  flex: 1;
}

.paymentHistory__thead {
  margin-bottom: 18px;
}

.paymentHistory__th {
  color: rgba(36, 36, 36, 0.5);
  font-size: 13px;
  font-weight: 500;
}

.paymentHistory__trow {
  padding: 26px 16px;
}

.paymentHistory__trow:nth-child(even) {
  background: rgba(234, 234, 234, 0.5);
}

.billingCycle {
  display: flex;
}

.billingCycle__btn {
  background-color: #d8d8d8;
  color: #757575;
  font-weight: 900;
  padding: 8px 16px;
}

.billingCycle__btn--selected {
  background-color: #4e67d8;
  color: #fff;
  font-weight: 900;
  padding: 8px 16px;
}

#billingCycle__btn--left {
  border-radius: 10px 0 0 10px;
}

#billingCycle__btn--right {
  border-radius: 0 10px 10px 0;
}

.confirmBilling__details {
  font-size: 14px;
  color: rgba(36, 36, 36, 0.7);
  margin-bottom: 18px;
  padding: 0px 60px;
}

.confirmBilling__save {
  margin: 32px 60px;
  display: flex;
  justify-content: flex-end;
}

.confirmBilling__nvm {
  color: #757575;
  font-weight: 500;
  font-size: 15px;
  margin-right: 16px;
}

.confirmBilling__yes {
  color: #fa4068;
  font-weight: 500;
  font-size: 15px;
}

.paymentInfo__form {
  padding-left: 60px;
}

.inviteNewUsers {
  padding: 0 60px 60px 0px;
  display: flex;
  flex-wrap: wrap;
}

.inviteNewUsers__input {
  padding-left: 60px;
  flex-basis: 100%;
  display: flex;
}

.inviteNewUsers__field {
  flex-basis: 100%;
}
