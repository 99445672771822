.upgradeAccount {
    flex-basis: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
}

.upgradeAccount__stepper {
    width: 100%;
    display: flex;
    background-color: #F1F2F2;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px 0;
}

.upgradeAccount__step {
    display: flex;
    align-items: center;
}

.upgradeAccount__step__index,
.upgradeAccount__step__index--active {
    margin-right: 8px;
    line-height: 22px;
    border-radius: 100px;
    padding: 3px 10px 3px 10px;
    font-weight: 700;
    color: #fff;
    font-size: 13px;
}

.upgradeAccount__step__index--active {
    background-color: var(--darkBlue);
}

.upgradeAccount__step__index {
    background-color: rgba(36, 36, 36, 0.5);
}

.upgradeAccount__step__label,
.upgradeAccount__step__label--active {
    font-weight: 700;
    font-size: 13px;
}

.upgradeAccount__step__label--active {
    color: #000;
}

.upgradeAccount__step__label {
    color: rgba(36, 36, 36, 0.5);
}