.settingsNavItem__container {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  border-left: 5px solid #f5f5f5;
  padding: 20px 5px 20px 20px;
  margin: 10px 0;
  font-weight: 600;
  letter-spacing: -0.4px;
  font-size: 1em;
  color: rgb(36, 36, 32, 0.7);
  text-align: left;
  transition: all 0.15s;
}

.settingsNavItem__container:hover {
  background: #e8e8f0;
  color: #141272;
}

.settingsNavItem__selected {
  border-left: 5px solid #141272;
  background: #e8e8f0;
  color: #141272;
  cursor: default;
}
