.search {
    flex-basis: 30%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 2%;
    flex-wrap: wrap;
}

.search__input {
    height: 50%;
    width: 100%;
    padding: 1.5% 0 1.5% 3%;
    font-size: 1em;
    background-color: #FAFAFA;
    border: 1px solid #BABABA;
    border-radius: 2px;
}

.search__popover {
    z-index: 1201;
    flex-basis: 100%;
}

.search__results {
    width: 100%;
    margin-top: 1%;
}

.search__results__item {
    padding: 5px;
}

.search__results__item:hover,
    .search__results__item:focus,
    .search__results__active {
    background-color: #f7f7f7;
}

.search__results__emoji {
    margin: 8px;
    font-size: 1.2em;
}

.search__results__tag {
    margin-left: 10px;
}