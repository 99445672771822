.auth {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start;
    background-color: #F5F5F5;
}

.auth__header {
    flex-basis: 100%;
    display: flex;
    height: 6%;
    background-color: #fff;
    align-items: center;
}

.auth__header__img {
    height: 70%;
    margin-left: 10%;
    margin-right: 5px;
}

.auth__header__title {
    font-weight: 900;
    font-size: 1.4em;
}

.auth__form {
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    align-content: flex-start;
}

.auth__form__card {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 24px;
    width: 30%;
}

.auth__form__title {
    margin: 5% 6%;
    font-weight: 900;
    font-size: 1.6em;
    flex-basis: 100%;
}

.auth__form__title--withEmoji {
    margin: 0% 6% 5% 6%;
    font-weight: 900;
    font-size: 1.6em;
    flex-basis: 100%;
}

.auth__form__title--long {
    text-align: center;
}

.auth__form__field--top {
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.auth__form__field {
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    margin: 10px 0;
}

.auth__form__input {
    width: 90%;
    font-weight: 500;
}

.auth__form__select {
    margin: 10px 0 !important;
    width: 90%;
    font-weight: 500;
    display: flex;
    justify-content: center;
}

.auth__form__select__emoji {
    margin-left: 5px;
}

.auth__form__select__text {
    margin-left: 10px;
}

.auth__form__submit {   
    box-sizing: border-box;
    width: 90%;
    padding: 18.5px 14px; /* to match MuiInputBase-input */
}

.auth__form__divider {
    border: 1.5px solid #979797;
    background-color: #979797;
    width: 20%;
    margin-top: 10px;
}

.auth__form__subtext {
    flex-basis: 100%;
    text-align: center;
    font-family: HelveticaNeue-Bold, 'Helvetica Neue';
    font-weight: 900;
    font-size: 1.1em;
    color: #384EB4;
    text-align: center;
    margin: 10px 0;
    display: flex;
    justify-content: center;
}

.auth__form__subtext__text {
    width: fit-content;
}

.auth__form__subtext__text:hover {
    text-decoration: underline;
    cursor: pointer;
}

.auth__footer {
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.auth__footer__text {
    font-weight: 700;
    flex-basis: 25%;
    text-align: center;
    color: #8C8C8C;
}

.auth__footer__text__link {
    text-decoration: underline;
}

.auth__footer__text__link:hover {
    color: #384EB4;
    cursor: pointer;
}

.auth__form__emoji {
    font-size: 5em;
    height: fit-content;
}

/* tablets and smaller */
@media only screen and (max-width: 768px) {
    .auth__form__card {
        margin-top: 10px;
        width: 95%;
        align-content: flex-start;
    }

    .auth__footer__text {
        flex-basis: 90%;
    }
}

/* windows bigger than tablets but small enough to cause sizing issues with % */
@media only screen and (max-width: 860px) and (min-width: 769px) {
    .auth__form__upload__prev {
        width: 200px;
        height: 200px;
        border-radius: 50%;
    }
}