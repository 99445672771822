.formAddWorkflowForm__fieldset--optional {
  border-top-style: solid !important;
  border-color: var(--lightGray) !important;
}

@media screen and (max-width: 1040px) {
  .formAddWorkflow__img--responsive {
    width: clamp(100px, 60vh, 830px) !important;
  }

  .formAddWorkflow__img--responsive--vertical {
    width: clamp(100px, 35vh, 410px) !important;
  }
}
