@import "./styles/animations.css";
@import "./styles/react-toastify-overrides.css";
@import "./styles/inputs.css";
@import "./styles/backgrounds.css";

/* Assign app-wide variables in root*/
:root {
  /* COLORS */

  /* Black used as background color for customToolips */
  --black: #242424;

  --lightGray: #f1f2f2;
  --gray: #d3d3d3;
  --darkGray: #7c7c7c;

  --lightestGreen: #eff5f0;
  --lightGreen: #ddeadf;
  --green: rgba(25, 116, 41, 0.6);
  --darkGreen: #197429;

  --lightLavender: #dcdbea;
  --lavender: #8988b8;

  --lightBlue: #f6f7fd;
  --blue: #4e67d8;
  --darkBlue: #141272;

  --lightRed: #feebef;
  --red: rgba(250, 64, 101, 0.6);
  --darkRed: #fa4065;

  --darkYellow: #ffb913;
}

/* removes mapbox label */
.mapboxgl-ctrl-bottom-right {
  display: none !important;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

.mb-5 {
  margin-bottom: 5px;
}

.loading {
  position: absolute;
  top: calc(50% - 22px - 54px);
  left: calc(50% - 44px);
  z-index: 1800;
}

/* BORDERS */
.border--dashed {
  border-bottom: 1px dashed #aaaaaa;
}

.border__listItem {
  border-top: 1px solid rgba(210, 213, 213, 0.5);
}

/* BUTTONS */
.btn {
  border-radius: 3px;
  font-weight: 900;
  font-size: 17px;
  text-align: center;
  border: none;
}

.btn--blue {
  color: #ffffff;
  background-color: #4e67d8;
}

.btn-disabled {
  opacity: 0.5;
  color: #242420;
  background-color: #eaeaea;
}

.btn-enabled {
  background: #141272;
  font-size: 1em;
  color: #ffffff;
}

.btn-enabled:hover {
  cursor: pointer;
}

.btn-enabled:active {
  background-color: #fff;
  color: #141272;
  box-shadow: inset 0px 0px 0px 2px #141272;
  box-sizing: border-box;
}

.btn-enabled:focus {
  outline: none;
}

.btnContainer {
  display: flex;
  align-items: baseline;
}

.btn__cancel {
  font-weight: 400;
  font-size: 0.8em;
  margin-right: 5px;
  color: #757575;
}

.btn__cancel:hover {
  cursor: pointer;
  font-weight: 600;
}

.btn__save {
  font-size: 0.8em;
  font-weight: 600;
  padding: 5px 10px;
  color: #4e67d8;
  border: 1px solid #4e67d8;
  border-radius: 50px;
}

.btn__save:hover {
  cursor: pointer;
  color: #ffffff;
  background-color: #4e67d8;
}

.btn__save:active {
  color: #4e67d8;
  background-color: #ffffff;
}

.cancel {
  color: #757575;
  font-weight: 700;
}

.save {
  color: #fa4065;
  font-weight: 700;
}

.delete {
  font: Arial;
  font-size: 1.3em;
  font-weight: 800;
  color: #fa4061;
}

.roundedBtn {
  color: #fff;
  font-weight: 600;
  padding: 15px 30px;
  border-radius: 50px;
}

@media only screen and (max-width: 425px) {
  .roundedBtn {
    padding: 8px 12px !important;
    text-align: center;
  }
}

/* CLICKABLE */
.click {
  cursor: pointer;
}

/* CARDS */
.card {
  background-color: #fff;
  box-shadow: 10px 10px 15px rgba(31, 30, 68, 0.05);
  border-radius: 10px;
  padding: 56px 60px;
}

.card__title {
  color: #242424;
  font-weight: 900;
  font-size: 1.5em;
  margin-bottom: 23px;
}

.card__subtitle {
  color: rgba(36, 36, 36, 0.6);
  margin-bottom: 60px;
}

/* CHIPS */
.chip {
  height: 2.5em;
  border-radius: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 5px 10px;
  margin-right: 5px;
  white-space: nowrap;
}

.chip--red {
  background: #feebef;
}

.chip--green {
  background: #ddeadf;
}

.chip--yellow {
  background: #fff8e7;
}

.chip--blue {
  background: #edeffb;
}

.chip--red__top,
.chip--green__top,
.chip--yellow__top,
.chip--blue__top {
  height: fit-content;
  flex-basis: 100%;
  font-size: 0.7em;
  font-weight: 800;
  letter-spacing: -0.4px;
}

.chip--red__bottom,
.chip--green__bottom,
.chip--yellow__bottom,
.chip--blue__bottom {
  height: fit-content;
  flex-basis: 100%;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chip--yellow__top {
  color: rgba(255, 185, 19, 0.7);
}

.chip--yellow__bottom {
  color: #ffb913;
}

.chip--green__top {
  color: rgba(25, 116, 41, 0.6);
}

.chip--green__bottom {
  color: #197429;
}

.chip--red__top {
  color: rgba(250, 64, 101, 0.6);
}

.chip--red__bottom {
  color: #fa4065;
}

.chip--blue__top {
  color: rgba(78, 103, 216, 0.6);
}

.chip--blue__bottom {
  color: #4e67d8;
}

/* DIALOGS */
.dialog {
  display: flex;
  flex-wrap: wrap;
  padding: 30px;
}

.dialog__title {
  font-size: 1.2em;
}

.dialog__btns {
  display: flex;
}

.dialog__btn {
  margin-right: 15px;
}

.dialog__list {
  flex-basis: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 5px 0;
}

.dialog__listItem {
  flex-basis: 100%;
}

/* HIDDEN */
.hidden {
  visibility: hidden;
}

/* MARGINS */
.mr--5 {
  margin-right: 5px;
}

.mr--10 {
  margin-right: 10px;
}

.mr--50 {
  margin-right: 50px;
}

.mb--5 {
  margin-bottom: 5px;
}

.mb--10 {
  margin-bottom: 10px;
}

.mt--10 {
  margin-top: 10px;
}

.mt--40 {
  margin-top: 40px;
}

.mt--45 {
  margin-top: 45px;
}

/* POPOVERS */
.popover {
  padding: 10px 5px;
}

/* SCROLL */
/* Hide scrollbar for Chrome, Safari and Opera */
.hide__scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide__scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* INPUT */
.input {
  height: 3em;
  padding-left: 10px;
  margin-bottom: 18px;
  font-size: 1em;
  border: 1px solid rgba(20, 18, 114, 0.4);
  border-radius: 3px;
  outline: none;
  box-sizing: border-box;
}

.input__title {
  color: #141272;
  font-weight: 500;
  margin-bottom: 16px;
}

/* LINK */
.link {
  color: blue;
  opacity: 0.6;
  text-decoration: underline;
}

/* PROFILE IMAGE */
.profileImage__upload {
  display: flex;
  justify-content: center;
}

.profileImage__upload > input {
  display: none;
}

.profileImage__upload__label {
  display: flex;
  justify-content: center;
}

.profileImage__upload__prev {
  width: 250px;
  height: 250px;
  border-radius: 50%;
}

.profileImage__upload__prev:active {
  border-radius: 50%;
  background-color: #cacaca;
}

/* STAMPS */

.stamp {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 900;
  font-size: 1.5em;
  border-radius: 50%;
}

.stamp--red {
  background-color: #feebef;
  color: #fa4065;
}

.stamp--grey {
  background: #f1f2f2;
  color: #757575;
}

.stamp--brown {
  background: #f1ece5;
  color: #784501;
}

.stamp--blue {
  color: #4e67d8;
  background: rgba(78, 103, 216, 0.1);
}

.tag {
  width: fit-content;
  height: fit-content;
  font-weight: 900;
  font-size: 0.8em;
  padding: 3px 4px;
  letter-spacing: 0.7;
  border-radius: 4px;
  text-transform: uppercase;
}

.tag--grey {
  background-color: #eaeaea;
  color: #757575;
}

.tag--grey--inverse {
  background-color: #ffffff;
  color: #aaaaaa;
}

.tag--blue {
  background-color: #ebedf7;
  color: #384eb4;
}

.tag--blue--inverse {
  background-color: #4e67d8;
  color: #fff;
}

.tag--red {
  background-color: #fa4065;
  color: #ffffff;
}

.tag--red--light {
  background-color: var(--lightRed);
  color: var(--darkRed);
}

.tag--purple {
  background: rgba(78, 103, 216, 0.1);
  color: rgba(20, 18, 114, 0.5);
}

.tag--purple--dark {
  background-color: #141272;
  color: #fff;
}

.tag--purple--inverse {
  color: #141272;
  background-color: #fff;
}

.tag--green {
  background-color: #ddeadf;
  color: #197429;
}

.tag--yellow {
  background-color: #fff8e7;
  color: #ffb913;
}

.tag--add {
  margin: 5px 3px;
  padding: 3px 9px;
  border-radius: 100px;
  background-color: #eaeaea;
  color: #757575;
}

.tag--add:hover,
.tag--selected:hover {
  cursor: pointer;
}

.tag--selected {
  background-color: #4e67d8;
  color: #fff;
}

/* TITLES */
.titleContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  padding-bottom: 15px;
}

.title {
  font-weight: 900;
}

/* TABLES */

.table {
  height: 100%;
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
}

.thead {
  max-height: 2em;
  background-color: rgba(20, 18, 114, 0.6);
  color: #fff;
  padding: 5px 8px;
}

.tbody {
  border: 1px solid #eaeaea;
  border-radius: 0 0 5px 5px;
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /*Firefox */
}

.tbody::-webkit-scrollbar {
  display: none;
}

.tbody--noScroll {
  max-height: none;
  overflow-y: hidden;
}

.tbody__inner--scroll {
  max-height: 211px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /*Firefox */
}

.tbody__inner--scroll::-webkit-scrollbar {
  display: none;
}

.trow,
.thead {
  display: flex;
  align-items: center;
  height: 3em;
}

.trow--focused {
  background-color: #ffda83 !important;
}

.trow:first-child .cell {
  display: flex;
  justify-content: center;
  align-items: center;
}

.td,
.th {
  flex: 3;
  margin: 18px 8px;
}

.td {
  font-weight: 400;
}

.th {
  font-size: 0.85em;
  font-weight: 600;
}

.th {
  margin-left: 5px;
}

.td--wide {
  flex: 5;
}

.td--xwide {
  flex: 6;
}

.td--xsmall {
  flex: 1;
}

.td--bold {
  font-weight: 700 !important;
}

.trow:nth-child(even) {
  background: #f1f2f2;
}

.tinput {
  width: 80%;
  border: 1px solid rgba(20, 18, 114, 0.7);
  border-radius: 3px;
  outline: none;
  height: 2em;
}

/* UNDERLINE */
.underline {
  text-decoration: underline;
}

/* FLEX */
.flex {
  display: flex;
}

.align-center {
  align-items: center;
}
