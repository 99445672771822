.Toastify__toast {
  min-height: 50px !important;
}

.Toastify__toast--info {
  background: var(--black) !important;
}

.Toastify__toast--success {
  background: var(--lightGreen) !important;
}

.Toastify__toast--success > .Toastify__toast-body,
.Toastify__toast--success > .Toastify__close-button {
  color: var(--darkGreen);
}

.Toastify__toast--error {
  background: var(--lightRed) !important;
}

.Toastify__toast--error > .Toastify__toast-body,
.Toastify__toast--error > .Toastify__close-button {
  color: var(--darkRed);
}

.Toastify__toast-body {
  font-size: 0.9rem !important;
}
