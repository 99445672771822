.tooltipInformation__btn {
  color: white;
  font-weight: 900;
  background-color: #bababa;
  border-radius: 50%;
  padding: 1px;
  height: 15px;
  width: 15px;
  transition: background-color 0.15s;
}

.tooltipInformation__btn:hover {
  background-color: var(--darkGray);
}

.tooltipInformation__btn--visible--true {
  background-color: var(--darkGray) !important;
  cursor: default;
}
