.mapContainer {
  position: absolute;
  top: 6vh;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  cursor: default;
}

/* MAP POPUP */
.popup__customer,
.popup__lp {
  font-weight: 800;
}
.marker {
  background-image: url("../../images/warehouse.png");
  background-size: cover;
  height: 25px;
  width: 25px;
  cursor: pointer;
}