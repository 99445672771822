.tooltipStructureCopy__btn {
  fill: #f1f2f2;
  margin-left: 8px;
  font-size: 14px !important;
  cursor: pointer;
  align-self: flex-end;
  transition: background-color 0.15s;
  place-self: center;
}

.tooltipStructureCopy__btn:hover {
  transition: background-color 0.15s;
  fill: #dfe2e2;
}
