.mapboxgl-popup-tip {
  display: none;
}

.mapboxgl-popup-content {
  background-color: rgba(0, 0, 0, 0) !important;
  box-shadow: none;
  cursor: default;
}

.mapboxgl-popup-close-button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fa406f;
  font-weight: 700;
  font-size: 1em;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
}

.popup {
  display: flex;
  align-items: stretch;
}

.popup__content {
  flex: 6;
  background: #ffffff;
  border: 1px solid #bababa;
  border-radius: 15px 15px 15px 0;
  padding: 15px 15px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.popup__title {
  text-align: center;
  font-weight: 900;
  margin-bottom: 10px;
  padding-bottom: 1px;
  color: #fa406f;
  border-bottom: 1px solid #000;
}

.popup__name {
  color: #141272;
  font-weight: 900;
  margin-bottom: 4px;
}

.popup__name:hover {
  cursor: pointer;
}

.popup__detail {
  color: #757575;
  margin-bottom: 4px;
}

.popup__btns {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
}

.popup__btn {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin-top: 5px;
}
