.popoutButton__button {
  color: var(--darkBlue);
  font-size: 0.85rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 700;
  padding: 0.75rem 0.95rem;
  width: 4rem;
  text-align: left;
  transition: all 0.15s;
}

.popoutButton__button:hover {
  background-color: var(--lightGray);
}

.popoutButton__button:active {
  background-color: var(--gray);
}
